<!-- eslint-disable no-unused-vars -->
<script>
import Swal from "sweetalert2";
export default {
  data() {
    return {
      productsData: [],
      trashList: [],
      totalRows: 1,
      current_page: 1,
      per_page: 15,
      trash_current_page: 1,
      trash_total_rows: 1,
      trash_per_page: 15,
      pageOptions: [5, 10, 25, 50],
      filter: null,
      filterOn: [],
      sortBy: "orderid",
      sortDesc: false,
      search: "",
      allFields: [
        { key: "formattedname", sortable: true, label: "Name" },
        // { key: "formattedDescription", label: "Description", thStyle: { width: "50%" } },
        { key: "is_active", label: "Status" },
        // { key: "is_most_popular", label: "Popular" },
        { key: "show_a_to_z_category", label: "A2Z" },
        { key: "most_popular_category", label: "Most Popular" },
        { key: "discounted_category", label: "Discounted" },
        { key: "recycled_category", label: "Recycled" },
        { key: "promotional_category", label: "Promotional" },
        { key: "Action" },
      ],
      trashFields: [
        { key: "product_images", label: "Image", thStyle: { width: "100" } },
        { key: "name", sortable: true, label: "Name" },
        { key: "description", label: "Description", thStyle: { width: "50%" } },
        { key: "Action" },
      ],
    };
  },
  mounted() {
    this.getTrashData(1)
    this.getProductData(1);
  },
  methods: {
    goToCreateProducts() {
      localStorage.setItem("activeTab", 0);
      this.$router.push({ name: "CreateProducts" });
    },
    searchProduct() {
      if (this.search) {
        let loader = this.$loading.show({
          loader: "dots",
        });
        this.$store.getters.client
          .get(`/catalog-product/products/?search=${this.search}`)
          .then((response) => {
            this.productsData = response.data.results;
            this.totalRows = response.data.total_count;
            this.per_page = response.data.per_page;
            this.applyBackgroundColor(this.search);
            loader.hide();
          });
      } else {
        this.getProductData(1);
      }
    },
    changeStatus(item) {
      this.$store.getters.client.patch(
        `/catalog-product/products/${item.id}/`,
        { is_active: item.is_active }
      );
    },
    toggleA2Z(item) {
      this.$store.getters.client.patch(
        `/catalog-product/products/${item.id}/`,
        { show_a_to_z_category: item.show_a_to_z_category }
      );
    },
    promotionalA2Z(item) {
      this.$store.getters.client.patch(
        `/catalog-product/products/${item.id}/`,
        { promotional_category: item.promotional_category }
      );
    },
    recycledA2Z(item) {
      this.$store.getters.client.patch(
        `/catalog-product/products/${item.id}/`,
        { recycled_category: item.recycled_category }
      );
    },
    discountedA2Z(item) {
      this.$store.getters.client.patch(
        `/catalog-product/products/${item.id}/`,
        { discounted_category: item.discounted_category }
      );
    },
    mostPopular(item) {
      this.$store.getters.client.patch(
        `/catalog-product/products/${item.id}/`,
        { most_popular_category: item.most_popular_category }
      );
    },
    getProductData(value) {
      let loader = this.$loading.show({
        loader: "dots",
      });
      this.$store.getters.client
        .get(`/catalog-product/products/?page=${value}`)
        .then((response) => {
          this.productsData = response.data.results;
          this.productsData.forEach((product) => {
            product.formattedname = product.name;
            product.formattedDescription = product.description;
          })
          this.totalRows = response.data.total_count;
          this.per_page = response.data.per_page;
        });

      loader.hide();
    },
    applyBackgroundColor(keyword) {
      this.productsData.forEach((product) => {
        const name = product.name.toLowerCase();
        const description = product.description.toLowerCase();
        const search = keyword.toLowerCase();
        if (name.includes(search) || description.includes(search)) {
          // Apply background color
          product.formattedname = this.highlightKeyword(name, search);
          product.formattedDescription = this.highlightKeyword(description, search);
        } else {
          product.formattedname = product.name;
          product.formattedDescription = product.description;
        }
      });
    },
    highlightKeyword(text, keyword) {
      const regex = new RegExp(keyword, 'gi');
      return text.replace(regex, `<span style="background-color: yellow;">$&</span>`);
    },

    getTrashData(value) {
      this.$store.getters.client
        .get(`/catalog-product/products-trash/`)
        .then((response) => {
          this.trashList = response.data.results;
          this.trash_total_rows = response.data.total_count;
          this.trash_per_page = response.data.per_page;
        });
    },
    confirm(row) {
      Swal.fire({
        title: "Move to trash",
        text: "You can delete it from trash",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
        // eslint-disable-next-line no-unused-vars
      }).then((result) => {
        this.$store.getters.client.patch(`/catalog-product/products/${row}/`, {
          is_trash: true,
        });
        this.productsData = this.productsData.filter((x) => {
          return x.id != row;
        });
      });
    },
    Restore(row) {
      Swal.fire({
        title: "Restore the Product",
        text: "It will store to all products",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Restore",
        // eslint-disable-next-line no-unused-vars
      }).then((result) => {
        this.$store.getters.client.patch(`/catalog-product/products-trash/${row.id}/`, {
          is_trash: false,
        });
        this.trashList = this.trashList.filter((x) => {
          this.productsData.push(x)
          return x.id != row.id;
        });
      });
    },
    deleteproduct(row) {
      Swal.fire({
        title: "Delete product permanently?",
        text: "You won't be able to restore the product again",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
        // eslint-disable-next-line no-unused-vars
      }).then((result) => {
        this.$store.getters.client.delete(`/catalog-product/products-trash/${row}/`);
        this.trashList = this.trashList.filter((x) => {
          return x.id != row;
        });
      });
    },
  },
  created() {
    localStorage.removeItem("activeTab");
  },
};
</script>

<template>
  <div class="row">
    <div class="add-button">
      <a href="javascript:void(0);" class="btn btn-primary" @click="goToCreateProducts">
        + Add New Product
      </a>
    </div>
    <div class="col-lg-12">
      <div class="card">
        <div class="card-body pt-0">
          <b-tabs nav-class="nav-tabs-custom">
            <b-tab title-link-class="p-3">
              <template v-slot:title>
                <a class="font-weight-bold active">All</a>
              </template>
              <div class="row mt-4">
                <div class="col-sm-12 col-md-6">
                  <div id="tickets-table_length" class="dataTables_length">
                    <label class="d-inline-flex align-items-center">
                      Show&nbsp;
                      <b-form-select v-model="per_page" size="sm" :options="pageOptions"></b-form-select>&nbsp;entries
                    </label>
                  </div>
                </div>
                <!-- Search -->
                <div class="col-sm-12 col-md-6">
                  <div id="tickets-table_filter" class="dataTables_filter text-md-right">
                    <label class="d-inline-flex align-items-center">
                      Search:
                      <b-form-input v-model="search" type="text" class="form-control form-control-sm ml-2"
                        @keypress.enter="searchProduct()"></b-form-input>
                    </label>
                  </div>
                </div>
                <!-- End search -->
              </div>
              <div class="table-responsive">
                <b-table class="table-centered product-table" :items="productsData" :fields="allFields" responsive="sm"
                  :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :filter="filter" :filter-included-fields="filterOn">
                  <!-- <template v-slot:cell(formattedname)="row">
                    <p v-html="row.item.formattedname" class="one-line"></p>
                  </template> -->
                  <template v-slot:cell(formattedDescription)="row">
                    <p v-html="row.item.formattedDescription" class="one-line"></p>
                  </template>
                  <template v-slot:cell(is_active)="row">
                    <div class="d-flex align-items-center">
                      <b-form-checkbox v-model="row.item.is_active" switch @change="changeStatus(row.item)" class="mb-1">
                        <label><span v-if="row.item.is_active">Active</span>
                          <span v-else>Draft</span></label>
                      </b-form-checkbox>
                    </div>
                  </template>
                  <!-- <template v-slot:cell(is_most_popular)="row">
                    <div class="d-flex align-items-center">
                      <b-form-checkbox v-model="row.item.is_most_popular" switch @change="togglePopular(row.item)"
                        class="mb-1">
                      </b-form-checkbox>
                    </div>
                  </template> -->
                  <!-- <template v-slot:cell(promotional_category)="row">
                    <div class="d-flex align-items-center">
                      <b-form-checkbox v-model="row.item.promotional_category" switch class="mb-1"
                        @change="promotionalA2Z(row.item)">
                      </b-form-checkbox>
                    </div>
                  </template>
                  <template v-slot:cell(recycled_category)="row">
                    <div class="d-flex align-items-center">
                      <b-form-checkbox v-model="row.item.recycled_category" switch class="mb-1"
                        @change="recycledA2Z(row.item)">
                      </b-form-checkbox>
                    </div>
                  </template>
                  <template v-slot:cell(discounted_category)="row">
                    <div class="d-flex align-items-center">
                      <b-form-checkbox v-model="row.item.discounted_category" switch class="mb-1"
                        @change="discountedA2Z(row.item)">
                      </b-form-checkbox>
                    </div>
                  </template>
                  <template v-slot:cell(most_popular_category)="row">
                    <div class="d-flex align-items-center">
                      <b-form-checkbox v-model="row.item.most_popular_category" switch class="mb-1"
                        @change="mostPopular(row.item)">
                      </b-form-checkbox>
                    </div>
                  </template>
                  <template v-slot:cell(show_a_to_z_category)="row">
                    <div class="d-flex align-items-center">
                      <b-form-checkbox v-model="row.item.show_a_to_z_category" switch class="mb-1"
                        @change="toggleA2Z(row.item)">
                      </b-form-checkbox>
                    </div>
                  </template> -->

                  <template v-slot:cell(action)="row">
                    <b-dropdown right toggle-class="arrow-none card-drop" class="float-right" variant="white">
                      <template v-slot:button-content>
                        <i class="mdi mdi-dots-vertical"></i>
                      </template>
                      <b-dropdown-item><router-link class="d-block" :to="`/edit-product/` + row.item.id">Edit</router-link></b-dropdown-item>
                      <b-dropdown-item><router-link class="d-block" :to="`/calculator/` + row.item.id">Calculator</router-link></b-dropdown-item>
                      <b-dropdown-item><router-link class="d-block" :to="`/product/${row.item.id}/update-price`">Update Price</router-link></b-dropdown-item>
                      <b-dropdown-item><router-link class="d-block" :to="`/product/${row.item.id}/manage-supplier`">Add
                          Supplier</router-link></b-dropdown-item>
                      <b-dropdown-item><router-link class="d-block"
                          :to="`/sorting/${row.item.id}`">Sorting</router-link></b-dropdown-item>
                      <b-dropdown-item @click="confirm(row.item.id)" variant="danger">Delete</b-dropdown-item>
                    </b-dropdown>
                  </template>
                </b-table>
              </div>
              <div class="row">
                <div class="col">
                  <div class="
                      dataTables_paginate
                      paging_simple_numbers
                      float-right
                    ">
                    <ul class="pagination pagination-rounded mb-0">
                      <!-- pagination -->
                      <b-pagination v-model="current_page" :total-rows="totalRows" :per-page="per_page"
                        @change="getProductData">
                      </b-pagination>
                    </ul>
                  </div>
                </div>
              </div>
            </b-tab>
            <b-tab title-link-class="p-3">
              <template v-slot:title>
                <a class="font-weight-bold active">Trash</a>
              </template>
              <div class="table-responsive">
                <b-table class="table-centered product-table" :items="trashList" :fields="trashFields" responsive="sm">
                  <template v-slot:cell(product_images)="row">
                    <img v-if="row.item.product_images.length > 0" class="rounded-4"
                      :src="row.item.product_images[0].image" alt="" width="50">
                  </template>
                  <template v-slot:cell(description)="row">
                    <p v-html="row.item.description" class="one-line"></p>
                  </template>

                  <template v-slot:cell(action)="row">
                    <div class="d-flex align-items-center">
                      <a href="javascript:void(0);" class="mr-3 text-primary" v-b-tooltip.hover data-toggle="tooltip"
                        title="Restore" @click="Restore(row.item)">
                        <i class="mdi mdi-refresh font-size-18"></i>
                      </a>
                      <a href="javascript:void(0);" class="text-danger" v-b-tooltip.hover title="Delete"
                        @click="deleteproduct(row.item.id)">
                        <i class="mdi mdi-trash-can font-size-18"></i>
                      </a>
                    </div>
                  </template>
                </b-table>
              </div>
              <div class="row">
                <div class="col">
                  <div class="
                      dataTables_paginate
                      paging_simple_numbers
                      float-right
                    ">
                    <ul class="pagination pagination-rounded mb-0">
                      <b-pagination v-model="trash_current_page" :total-rows="trash_total_rows" :per-page="trash_per_page"
                        @change="getTrashData">
                      </b-pagination>
                    </ul>
                  </div>
                </div>
              </div>
            </b-tab>
          </b-tabs>
        </div>
      </div>
    </div>
  </div>
</template>
<style>
.add-button {
  margin-bottom: 27px;
  margin-left: 14px;
}

.one-line {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
</style>
