<template>
  <b-row v-if="products" class="mb-4 mp-promotional">
    <b-col cols="12">
      <b-card title="product Variants" class="h-100">
        <b-row>
          <b-col
            cols="4"
            v-for="(item, i) in products.variants"
            :key="i"
            class="bg-light rounded p-3"
          >
            <img :src="item.digital_assets[0].url" alt="" class="w-100 mb-3" />
            <table class="w-100">
              <tbody>
                <tr class="border-bottom border-white">
                  <th>SKU</th>
                  <td>{{ item.sku }}</td>
                </tr>
                <tr class="border-bottom border-white">
                  <th>Color</th>
                  <td>{{ item.color_description }}</td>
                </tr>
                <tr class="border-bottom border-white">
                  <th>Category</th>
                  <td>
                    <ul class="px-3">
                      <li>{{ item.category_level1 }}</li>
                      <li>{{ item.category_level2 }}</li>
                      <li>{{ item.category_level3 }}</li>
                    </ul>
                  </td>
                </tr>
                <tr class="border-bottom border-white">
                  <th>Stock</th>
                  <td>{{ item.stock_variant.qty }}</td>
                </tr>
                <tr>
                  <th>Price</th>
                  <td>
                    <table class="w-100">
                      <thead>
                        <tr>
                          <th class="px-0">QTY</th>
                          <th>Price</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(price, p) in item.price_variant.scale_prices" :key="p">
                          <td class="px-0">{{ price.minimum_quantity }}</td>
                          <td>{{ price.price }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
              </tbody>
            </table>
          </b-col>
        </b-row>
      </b-card>
    </b-col>
    <b-col cols="6">
      <b-card title="Update Details" class="h-100">
        <b-form>
          <b-form-group label="Product name">
            <b-form-input v-model="products.product_name"></b-form-input>
          </b-form-group>
          <b-form-group label="Short Description">
            <b-form-input v-model="products.short_description"></b-form-input>
          </b-form-group>
          <b-form-group label="Long Description">
            <b-form-textarea rows="5" v-model="products.long_description">
            </b-form-textarea>
          </b-form-group>
          <b-form-group label="Product Description:(DP)">
            <b-form-textarea rows="8" v-model="products.description">
            </b-form-textarea>
          </b-form-group>
          <b-form-group label="Commercial Description">
            <b-form-textarea
              rows="5"
              v-model="products.commercial_description"
            ></b-form-textarea>
          </b-form-group>
          <hr>
          <h4>Meta details</h4>
          <div class="form-group">
            <label>Page Title</label>
            <input type="text" class="form-control" id="" for="text" name="name" v-model="products.page_title" />
          </div>
          <div class="mb-3">
            <label>Description</label>
            <textarea class="w-100 form-control" rows="5" v-model="products.engine_description"></textarea>
          </div>

          <div class="form-group">
            <label>Url and handle</label>
            <input type="text" class="form-control" id="" for="text" name="name" v-model="products.url_handle" />
          </div>
          <div class="form-group">
            <label>Meta keywords</label>
            <input type="text" class="form-control" id="exampleInputEmail1" for="text" name="name"
              v-model="products.meta_keywords" />
          </div>
          <b-button
            type="button"
            variant="success"
            class="float-right"
            @click="updateProduct()"
            >Update</b-button
          >
        </b-form>
      </b-card>
    </b-col>
    <!-- <pre>{{products}}</pre> -->
  </b-row>
</template>

<script>
export default {
  data() {
    return {
      products: null,
      totalRows: 1,
      current_page: 1,
      per_page: 15,
      allFields: [
        { key: "digital_assets", sortable: true, label: "Image" },
        { key: "short_description", sortable: true, label: "Name" },
        {
          key: "long_description",
          label: "Description",
          thStyle: { width: "40%" },
        },
        { key: "Action" },
      ],
    };
  },
  mounted() {
    this.getProductData();
  },
  methods: {
    async getProductData() {
      let loader = this.$loading.show({
        loader: "dots",
      });
      await this.$store.getters.client
        .get(`/midocean/promotional-products/${this.$route.params.id}`)
        .then((response) => {
          console.log(response);
          this.products = response.data;
        });

      loader.hide();
    },
    async updateProduct() {
      const payload = {
        product_name: this.products.product_name,
        short_description: this.products.short_description,
        long_description: this.products.long_description,
        description: this.products.description,
        commercial_description: this.products.commercial_description,
        page_title: this.products.page_title,
        engine_description: this.products.engine_description,
        url_handle: this.products.url_handle,
        meta_keywords: this.products.meta_keywords
      };
      let loader = this.$loading.show({
        loader: "dots",
      });
      await this.$store.getters.client
        .patch(
          `/midocean/promotional-products/${this.$route.params.id}/`,
          payload
        )
        .then(() => {
          this.getProductData();
        });
      loader.hide();
    },
  },
};
</script>

<style>
.mp-promotional table tr td, .mp-promotional table tr th{
  padding: 8px 12px;
}
.mp-promotional table tr{
  vertical-align: top;
}
</style>