<script>
import { MEDIA } from "@/api/global.env.js"
import Multiselect from "vue-multiselect";
import Swal from "sweetalert2";
export default {
  components: { Multiselect },
  data() {
    return {
      MEDIA: MEDIA,
      orderData: {},
      manageStatus: false,
      supplierBar: false,
      isEdit: false,
      id: null,
      selectedProduct: null,
      supplierList: [],
      totalSupplierRows: 0,
      currentsupplierPage: 0,
      supplierData: {
        order_id: this.$route.params.id,
        order_items: null,
        supplier: null,
        delivery_cost: 0,
        delivery_date: null,
        delivery_service: "",
        delivery_address: "",
        delivery_type: "back-to-customer"
      },
      selectedDeliveryItem: null,
      deliveDateUpdateModal: false,
      allOrderItems: null,
      selectedForSupplier: null,
      deliveryOptions: ['express', 'standard', 'value'],
      deliveryTypeOptions: ['back-to-customer', 'back-to-us'],
      selectedOrderStatus: "",
      orderHistory: null,
      dispatchData: {
        url: '',
        track_id: '',
        comments: ''
      },
      dispatchOption: 'all',
      selectedItems: [],
      selectedTracking: null,
      dispatchModal: false,
      trackModal: false,
    };
  },
  mounted() {
    this.getOrder();
  },
  methods: {
    async getOrder() {
      let loader = this.$loading.show({
        loader: "dots",
      });
      this.order_id = this.$route.params.id;
      this.$store.getters.client
        .get(`/order/get-order-details/${this.order_id}`)
        .then((response) => {
          this.orderData = response.data.results;
          this.orderHistory = this.orderData.order_items.map((orderItem) => {
            return { id: orderItem.id, history: orderItem.all_tracking_status, proofing: orderItem.artwork_proofing, name: orderItem.product_data.name }
          })
          this.allOrderItems = this.orderData.order_items.map((orderItem) => {
            if(orderItem.product_id){
              return {
                order_item: orderItem.id, 
                name: orderItem.product_data?.name,
                item_cost: Number(orderItem.item_price), 
                vat: 0, 
                isVatApplicale: true, 
                is_unit_price: false, 
                unit_price: null, 
                quantity_ordered: orderItem.qty_ordered, 
                artwork_proofing: orderItem.artwork_proofing.find(attachment => attachment.supplier_attachments !== '') }
              }
              if(orderItem.quotation_product_id){
                return { 
                  order_item: orderItem.id, 
                  name: orderItem.quotation_product?.name, 
                  item_cost: orderItem.quotation_product?.quotation_product_price[0].supplier_price, 
                  vat: 0, 
                  isVatApplicale: true, 
                  is_unit_price: false, 
                  unit_price: null, 
                  quantity_ordered: orderItem.qty_ordered, 
                  artwork_proofing: orderItem.artwork_proofing.find(attachment => attachment.supplier_attachments !== '') }
              }
              if(orderItem.midocean_product_id){
                return { 
                  order_item: orderItem.id, 
                  name: orderItem.midocean_product.promotional_product.short_description, 
                  item_cost: orderItem.midocean_product.price, 
                  vat: 0, 
                  isVatApplicale: true, 
                  is_unit_price: false, 
                  unit_price: null, 
                  quantity_ordered: orderItem.qty_ordered, 
                  artwork_proofing: orderItem.artwork_proofing.find(attachment => attachment.supplier_attachments !== '') }
              }
            }
          )
          console.log(this.allOrderItems)
          this.showDeliveryTime()
          this.itemsPrice()
          this.itemVat()
          loader.hide();
          this.orderData.order_items.forEach((ele) => {
            if(ele.product_id){
              this.getSupplierData({product_id:ele.product_id})
              return
            }
            if(ele.quotation_product_id){
              this.getSupplierData({quotation_product_id:ele.quotation_product_id})
              return
            }
            if(ele.midocean_product_id){
              this.getSupplierData({midocean_product_id:ele.midocean_product_id})
              return
            }
          })
        }).catch((error) => {
          console.log(error)
          alert('some error appeared, Try again after some time.')
          loader.hide()
        });
    },

    itemsPrice() {
      this.orderData.itemsTotalPrice = this.orderData.order_items.reduce((accumulator, obj) => {
        return accumulator + Number(obj.item_price);
      }, 0);
    },
    itemVat() {
      this.orderData.itemsProductVat = this.orderData.order_items.reduce((accumulator, obj) => {
        return accumulator + Number(obj.vat_amount);
      }, 0);
    },
    showDeliveryTime() {
      let orderDate = this.orderData.created_at;
      this.orderData.order_items.forEach((element) => {
        const deliveryDate = this.$moment(orderDate).add(element.delivery_date, 'days');
        const currentTime = this.$moment();
        const remainingDuration = this.$moment.duration(deliveryDate.diff(currentTime));
        element.deliveryDate = remainingDuration.days();
      })
    },
    async getSupplierData(product) {
      try {
        const response = await this.$store.getters.client.post(`/order/list-supplier/`, product);
        const supplierData = response.data;
        for (const SUPPLIER of supplierData) {
          if (!this.supplierList.some(existingSupplier => existingSupplier.id === SUPPLIER.id)) {
            // Add the new supplier to the list if it doesn't exist
            this.supplierList.push(SUPPLIER);
          }
        }
      } catch (err) {
        console.error(err);
      }
    }
    ,
    selecProducttItem(item) {
      // console.log(item)
      this.supplierData.delivery_service = this.orderData.delivery_upgrade
      this.selectedProduct = item;
      this.supplierBar = true;
    },
    dateDisabled(ymd, date) {
      const weekday = date.getDay()
      return weekday === 0 || weekday === 6
    },
    updateSupplier() {
      this.supplierData.supplier = this.supplierData.supplier.id
      this.supplierData.delivery_cost = Number(this.supplierData.delivery_cost)
      if (this.supplierData.delivery_type === "back-to-customer") {
        this.supplierData.delivery_address = this.orderData.shipping_first_name + ' ' + this.orderData.shipping_last_name + ' ' + this.orderData.shipping_company + ' ' + this.orderData.shipping_street1 + ' ' + this.orderData.shipping_country + ' ' + this.orderData.shipping_postal_code + ' ' + this.orderData.shipping_mobile
      } else {
        this.supplierData.delivery_address = 'Pankaj Tuli, Digital Press, UK England, LE43NA, 800 0000 312'
      }
      this.supplierData.order_items = this.selectedForSupplier.map(item => {
        return {
          ...item,
          item_cost: Number(item.item_cost),
          unit_price: item.is_unit_price ? (item.item_cost / item.quantity_ordered).toFixed(2) : null,
          vat: item.isVatApplicale ? (item.item_cost / 5).toFixed(2) : 0,
          artwork_proofing: item.artwork_proofing ? item.artwork_proofing.supplier_attachments : '',
        }
      })
      this.$store.getters.client
        .post(`/order/supplier-assign/`, this.supplierData)
        .then(() => {
          this.supplierBar = false;
          this.getOrder();
        });
    },
    updateStatus(item) {
      this.$store.getters.client
        .patch(`/order/order-items/${item}/`, {
          status: this.selectedProduct.status,
        })
        .then(() => {
          this.manageStatus = false;
          this.getOrder();
        });
    },
    addOrderStatus() {
      this.$store.getters.client
        .post(`/order/order-activity/`, {
          old_status: "",
          new_status: this.selectedOrderStatus,
          order_id: this.$route.params.id,
          // order_item_id: this.selectedProduct.id,
        })
        .then(() => {
          this.selectedOrderStatus = "";
          this.getOrder();
        });
    },
    nameWithDetails({ company_name, descriptions }) {
      return `${company_name} — ${descriptions}`
    },
    itemsForTracking() {
      if (this.dispatchOption === 'all') {
        this.selectedItems = []
        this.orderData.order_items.forEach(element => {
          if (element.admin_tracking.length === 0) {
            this.selectedItems.push(element.id)
          }
        });
      }
      else {
        return this.selectedItems
      }
    },
    async addTracking() {
      this.itemsForTracking()
      let formData = {
        ...this.dispatchData,
        order_item: this.selectedItems,
        order: this.orderData.id
      }
      if (this.selectedItems.length > 0) {
        let loader = this.$loading.show({
          loader: "dots",
        });
        try {
          await this.$store.getters.client.post(`/order/admin-track/`, formData)
          // this.$store.getters.client.patch(`/order/supplier-assign/${this.orderData.id}/`, { status: 'dispatched' });
          this.selectedItems = null
        } catch (error) {
          alert(error)
        }
        finally {
          loader.hide()
          this.getOrder()
          this.dispatchModal = false
        }
      }
      else {
        alert('All items are already dispatched')
      }
    },
    editDelivery(item) {
      this.selectedDeliveryItem = {
        oldDeliveryDate: item.delivery_date[item.delivery_date.length - 1].delivery_date,
        order_item: item.id,
        delivery_meathod: item.delivery_date[item.delivery_date.length - 1].delivery_meathod,
        delivery_date: item.delivery_date[item.delivery_date.length - 1].delivery_date
      }
      this.deliveDateUpdateModal = true
    },
    updateDeliveryDate() {
      this.$store.getters.client
        .post(`/order/item-delivery/`, this.selectedDeliveryItem)
        .then(() => {
          this.deliveDateUpdateModal = false
          this.getOrder();
        });
    },
    updateTracking(details) {
      this.selectedTracking = details;
      this.trackModal = true
    },
    async updateTrackingDetails() {
      const trackData = this.selectedTracking
      let loader = this.$loading.show({
        loader: "dots",
      });
      try {
        await this.$store.getters.client.patch(`/order/admin-track/${trackData.id}/`, trackData)
        this.getOrder()
      } catch (error) {
        alert(error)
      }
      finally {
        this.trackModal = false
        loader.hide()
      }
    },
    async uploadArtwork(event, item) {
      console.log(item, event)
      const FILE = await event.target.files[0];
      if (FILE.size > 104857600) { // 104857600 bytes = 100MB
        alert("File size exceeds the limit of 100MB. Please select a smaller file.");
        return;
      }
      if (FILE) {
        let formData = new FormData();
        formData.append("user", this.orderData.user.id);
        formData.append("order_item_id", item.id);
        formData.append("artwork", FILE);
        try {
          const response = await this.$store.getters.client.post(`/order/user-artworks/`, formData)
          const artwork = response.data;
          console.log(artwork)
        } catch (error) {
          // this.$toast.error(error);
          alert(error)
        }
        finally {
          this.getOrder()
          // this.loading = false
        }
      }
    },
    sendLink(value){
      Swal.fire({
        title: "Send Email?",
        text: "Do you want to send email for upload artwork",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, Send!",
        // eslint-disable-next-line no-unused-vars
      }).then((result) => {
        console.log(result, value)
        if (result.isConfirmed) {
          this.$store.getters.client.get(`/order/order-artwork/${value.order_id}`)
          // this.updateQuote(value)
        }
      });
    }
  },
  beforeMount() {
    window.addEventListener("mousewheel", this.handleScroll);
  },

  beforeDestroy() {
    window.removeEventListener("mousewheel", this.handleScroll);
  },
};
</script>

<template>
  <div class="row">
    <div class="col-md-12">
      <div class="mb-3 b-inline">
        <i class="fas fa-arrow-left"></i>
        <span class="mr-2 ml-3">#{{ orderData.number }}</span>
        <a href="#" class="badge badge-success">PAID</a>
      </div>
    </div>
    <div class="col-12">
      <div class="card">
        <!-- {{ allOrderItems }} -->
        <div class="card-body">
          <div class="table-responsive">
            <table class="table table-centered mb-0 table-nowrap">
              <thead class="bg-light">
                <tr>
                  <th style="width: 120px">Product</th>
                  <th>Product Desc</th>
                  <th>Design</th>
                  <th>Quantity</th>
                  <th>Item Status</th>
                  <th>Studio </th>
                  <th>Supplier</th>
                  <th>Artwork charge</th>
                  <th>Delivery Method</th>
                  <th class="text-right">Price</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in orderData.order_items" :key="item.id">
                  <td>
                    <img :src="item.midocean_product_id ? item.midocean_product.digital_assets[0].url : item.product_data?.product_images[0]?.image" alt="product-img" title="product-img" class="avatar-md" />
                    <!-- // <img v-if="item.quotation_product?.image" :src=" item.midocean_product_iditem.quotation_product?.image" alt="product-img"
                    //   title="product-img" class="avatar-md" /> -->
                  </td>
                  <td>
                    <h5 class="font-size-14 text-truncate">
                      <a href="ecommerce-product-detail.html" class="text-dark">{{ item.product_data?.name ||
                        item.quotation_product?.name || item.midocean_product?.promotional_product?.short_description }}</a>
                    </h5>
                    <template v-if="item.quotation_product?.description || item.midocean_product?.promotional_product.long_description">
                      <div v-html="item.quotation_product?.description"></div>
                    </template>
                    <template v-if="item.variant?.length > 0">
                      <div v-for="varient in item.variant" :key="varient.id">
                        <div>{{ varient.attribute.name }} : <b>{{ varient.attribute_value.name }}</b></div>
                      </div>
                    </template>
                  </td>
                  <td>
                    <b-button-group vertical size="sm">
                      <b-button variant="success">
                        <a class="d-block text-white" :href="item.user_artwork.artwork" target="_blank">Artwork</a>
                      </b-button>
                      <b-button type="button" variant="warning" @change="uploadArtwork($event, item)">
                        <input type="file" :id="'artwork' + item.id" hidden />
                        <label :for="'artwork' + item.id">
                          Upload
                        </label>
                      </b-button>
                      <b-button variant="primary" @click="sendLink(item)">Send Link</b-button>
                    </b-button-group>
                  </td>
                  <td>{{ item.qty_ordered }}</td>
                  <td>
                    <b-button size="sm" disabled v-if="item.status == 'pending'" variant="warning">PENDING</b-button>
                    <b-button size="sm" disabled v-if="item.status == 'proof_sent'" variant="info">PROOF SENT</b-button>
                    <b-button size="sm" disabled v-if="item.status == 'rejected'" variant="danger">ARTWORK
                      REJECTED</b-button>
                    <b-button size="sm" disabled v-if="item.status == 'accepted'" variant="success">ARTWORK
                      APPROVED</b-button>
                    <b-button size="sm" disabled v-if="item.status == 'in_production'" class="in-production">IN
                      PRODUCTION</b-button>
                    <b-button size="sm" disabled v-if="item.status == 'delayed'" variant="delay">DELAYED</b-button>
                    <b-button size="sm" disabled v-if="item.status == 'dispatched'" class="dispatch">DISPATCHED</b-button>
                    <b-button size="sm" disabled v-if="item.status == 'delivered'" class="delivered">DELIVERED</b-button>
                    <b-button size="sm" disabled v-if="item.status == 'cancelled'" variant="danger">CANCELLED</b-button>
                    <b-button size="sm" disabled v-if="item.status == 'refunded'" variant="secondry">REFUNDED</b-button>
                  </td>
                  <td>
                    <b-dropdown size="sm" class="m-2">
                      <template v-slot:button-content>
                        Studio Activity
                      </template>
                      <b-dropdown-item-button>
                        <ul class="list-unstyled activity-wid mb-5">
                          <li class="activity-list pb-3" v-for="(prrof, index) in item.artwork_proofing" :key="index">
                            <div class="activity-icon avatar-xs">
                              <span class=" avatar-title bg-soft-primary text-primary rounded-circle"></span>
                            </div>
                            <div>
                              <div>
                                <h5 class="font-size-13">
                                  <b-badge v-if="prrof.status == 'pending'" variant="warning">PENDING</b-badge>
                                  <b-badge v-if="prrof.status == 'proof_sent'" variant="info">PROOF SENT</b-badge>
                                  <b-badge v-if="prrof.status == 'rejected'" variant="danger">ARTWORK REJECTED</b-badge>
                                  <b-badge v-if="prrof.status == 'accepted'" variant="success">ARTWORK APPROVED</b-badge>
                                  <b-badge v-if="prrof.status == 'in_production'" class="in-production">IN
                                    PRODUCTION</b-badge>
                                  <b-badge v-if="prrof.status == 'delayed'" variant="delay">DELAYED</b-badge>
                                  <b-badge v-if="prrof.status == 'dispatched'" class="dispatch">DISPATCHED</b-badge>
                                  <b-badge v-if="prrof.status == 'delivered'" class="delivered">DELIVERED</b-badge>
                                  <b-badge v-if="prrof.status == 'cancelled'" variant="danger">CANCELLED</b-badge>
                                  <b-badge v-if="prrof.status == 'refunded'" variant="secondry">REFUNDED</b-badge>
                                </h5>
                                <div v-if="prrof.attachments"><a :href="MEDIA + prrof.attachments">Studio Artwork</a>
                                </div>
                                <div v-if="prrof.supplier_attachments"><a
                                    :href="MEDIA + prrof.supplier_attachments">Supplier Artwork</a></div>
                                <div class="bg-light">{{ prrof.comment }}</div>
                                <div class="mt-2 text-muted"> {{ $moment(prrof.created_at).tz('Europe/London').format("DD MMM, YYYY | HH:mm") }}
                                </div>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </b-dropdown-item-button>
                    </b-dropdown>
                  </td>
                  <td>
                    <b-button type="button" size="sm" @click="selecProducttItem(item)"
                      :disabled="Object.keys(item.assign_supplier).length !== 0"
                      :variant="Object.keys(item.assign_supplier).length !== 0 ? 'success' : 'warning'">{{
                        Object.keys(item.assign_supplier).length !== 0
                        ? item.assign_supplier.company_name
                        : "Unassigned"
                      }}</b-button>
                  </td>
                  <td>&#163;{{ item.design_service_price }}</td>
                  <td>
                    <span class="position-relative delivery-date-history" v-if="item.delivery_date.length > 0">
                      {{ item.delivery_date[item.delivery_date.length - 1].delivery_meathod }} <br>
                      <a href="javascript:void(0);" role="button"
                        :disabled="Object.keys(item.assign_supplier).length !== 0" class="mr-3 text-primary"
                        v-b-tooltip.hover data-toggle="tooltip" title="Edit" @click="editDelivery(item)">
                        {{ $moment(item.delivery_date[item.delivery_date.length - 1].delivery_date).tz('Europe/London').format("DD MMM, YYYY") }}
                      </a>
                      <ul v-if="item.delivery_date.length > 1">
                        <li v-for="(date, d) in item.delivery_date" :key="d">
                          <b>Method : </b>{{ date.delivery_meathod }} <br>
                          <b>Date : </b>{{ $moment(date.delivery_date).tz('Europe/London').format("DD MMM, YYYY") }} <br>
                          <small>Updated on :{{ $moment(date.created_at).tz('Europe/London').format("DD MMM, YYYY") }}</small>
                        </li>
                      </ul>
                    </span>
                  </td>
                  <td class="text-right">
                    &#163;{{ item.item_price }}
                  </td>
                </tr>
                <tr class="bg-light text-right">
                  <th scope="row" colspan="9">Sub Total :</th>

                  <td>&#163;{{ orderData?.itemsTotalPrice?.toFixed(2) }}</td>
                </tr>
                <tr class="bg-light text-right">
                  <th scope="row" colspan="9">Shipping Charge ({{ orderData.delivery_upgrade }}) :</th>

                  <td>&#163;{{ orderData?.shipping_amount?.toFixed(2) }}</td>
                </tr>
                <tr class="bg-light text-right">
                  <th scope="row" colspan="9">VAT :</th>

                  <td>&#163;{{ orderData?.vat_amount?.toFixed(2) }}</td>
                </tr>
                <tr class="bg-light text-right">
                  <th scope="row" colspan="9">Total :</th>

                  <td>&#163;{{ orderData?.price_amount?.toFixed(2) }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-4">
      <div class="card position-relative">
        <div class="card-body">
          <h4 class="card-title ">Order History</h4>
          <div v-for="(history, i) in  orderHistory" :key="i">
            <div><b>{{ history.name }}</b></div>
            <div class="d-flex">
              <div class="col-12">
                <ul class="list-unstyled activity-wid mb-5">
                  <li class="activity-list pb-3" v-for="(item, index) in history.history" :key="index">
                    <div class="activity-icon avatar-xs">
                      <span class=" avatar-title bg-soft-primary text-primary rounded-circle"></span>
                    </div>
                    <div>
                      <div>
                        <h5 class="font-size-13">
                          <b-badge v-if="item.status == 'pending'" variant="warning">PENDING</b-badge>
                          <b-badge v-if="item.status == 'proof_sent'" variant="info">PROOF SENT</b-badge>
                          <b-badge v-if="item.status == 'rejected'" variant="danger">ARTWORK REJECTED</b-badge>
                          <b-badge v-if="item.status == 'accepted'" variant="success">ARTWORK APPROVED</b-badge>
                          <b-badge v-if="item.status == 'in_production'" class="in-production">IN PRODUCTION</b-badge>
                          <b-badge v-if="item.status == 'delayed'" variant="delay">DELAYED</b-badge>
                          <b-badge v-if="item.status == 'dispatched'" class="dispatch">DISPATCHED</b-badge>
                          <b-badge v-if="item.status == 'delivered'" class="delivered">DELIVERED</b-badge>
                          <b-badge v-if="item.status == 'cancelled'" variant="danger">CANCELLED</b-badge>
                          <b-badge v-if="item.status == 'refunded'" variant="secondry">REFUNDED</b-badge>
                        </h5>
                        <div class="mt-2 text-muted"> {{ $moment(item.created_at).tz('Europe/London').format("DD MMM, YYYY | HH:mm") }}</div>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-4">
      <div class="card position-relative">
        <div class="card-body">
          <h4 class="card-title mb-4">Order Activity</h4>
          <div class="border px-3 mb-4">
            <select name="" id="" class="form-control border-0 px-2" v-model="selectedOrderStatus">
              <option value="" disabled>Select Status to update</option>
              <option value="artwork pending" class="bg-primary text-white">Artwork Pending</option>
              <option value="Proff Sent" class="bg-secondary text-white">Proff Sent</option>
              <option value="in production" class="bg-success text-white">In Production</option>
              <option value="delay" class="bg-warning text-white">Order Delay</option>
              <option value="dispatch" class="bg-info text-white">Dispatched</option>
              <option value="cancelled" class="bg-danger text-white">Cancel</option>
            </select>
            <hr class="my-0" />
            <div class="my-2">
              <b-row align-h="end" class="mx-0">
                <b-button variant="primary" size="sm" :disabled="!selectedOrderStatus"
                  @click="addOrderStatus">Update</b-button>
              </b-row>
            </div>
          </div>
          <div data-simplebar>
            <ul class="list-unstyled activity-wid mb-5">
              <li class="activity-list" v-for="(item, j) in orderData.order_activity" :key="j">
                <div class="activity-icon avatar-xs">
                  <span class=" avatar-title bg-soft-primary text-primary rounded-circle"></span>
                </div>
                <div>
                  <h5 class="font-size-13 mb-0">
                    {{ item.new_status }}
                  </h5>
                  <small class="text-muted">{{
                    $moment(item.created_at).tz('Europe/London').format("DD MMM, YYYY | HH: mm")
                  }}</small>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <b-card title="Supplier Tracking details">
        <!-- <a href="javascript:void(0)" role="button" @click="dispatchModal = true">ADD USER TRACKING</a> -->
        <div v-for="(item, index) in orderData.order_items" :key="index" class="border-top py-2 mt-2">
          <b>{{ item.product_data.name }}</b> <br>
          <div v-for="(track, k) in item.tracking_order" :key="k">
            <b>Link :</b> <a :href="track.url">{{ track.url }}</a> <br>
            <b>ID : </b> {{ track.track_id }}
            <div class="bg-light"><b>Comment : </b> {{ track.comments }}</div>
            <b><i class="mdi mdi-clock-outline"></i> : </b> {{ $moment(track.created_at).tz('Europe/London').format("DD MMM, YYYY | HH:mm") }}
          </div>
        </div>
      </b-card>
    </div>
    <b-sidebar width="600px" v-model="supplierBar" title="Manage supplier" id="manageSupplier" left backdrop shadow
      header-class="d-flex justify-content-between">
      <template #footer="{ hide }">
        <div class="px-3 py-2">
          <b-button variant="primary" @click="updateSupplier(), hide;" class="w-100 text-uppercase">
            Assign supplier
          </b-button>
        </div>
      </template>
      <div class="px-3 py-2">
        <b-form>
          <div class="forn-group my-3">
            <label>Select Supplier</label>
            <multiselect name="supplier" v-model="supplierData.supplier" :custom-label="nameWithDetails"
              :options="supplierList" label="company_name" track-by="id" :value.sync="supplierData.supplier">
            </multiselect>
          </div>
          <div class="forn-group my-3" v-if="allOrderItems">
            <label>Select Items</label>
            <multiselect name="supplier" v-model="selectedForSupplier" :options="allOrderItems" :multiple="true"
              label="name" track-by="order_item" :hide-selected="true" :value.sync="selectedForSupplier"></multiselect>
          </div>
          <!-- {{ selectedForSupplier }} -->
          <b-row v-if="selectedForSupplier">
            <b-col cols="12" v-for="(item, idx) in selectedForSupplier" :key="idx">
              <b-form-group :label="item.name">
                <b-input-group class="mb-2">
                  <b-input-group-prepend is-text>
                    <b-form-checkbox :id="`unitPrice${item.name}`" v-model="item.is_unit_price"
                      :name="`unitPrice${item.name}`" :value="true" :unchecked-value="false">
                      UNIT PRICE
                    </b-form-checkbox>
                  </b-input-group-prepend>
                  <!-- <b-form-input :for="item.name" type="number" :name="item.name" v-model="item.unit_price"></b-form-input> -->
                  <b-input-group-prepend is-text>
                    <b-form-checkbox :id="`checkbox${item.name}`" v-model="item.isVatApplicale" :name="item.name"
                      :value="true" :unchecked-value="false">
                      VAT
                    </b-form-checkbox>
                  </b-input-group-prepend>
                  <b-form-input :for="item.name" type="number" :name="item.name" v-model="item.item_cost"></b-form-input>
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group label="Delivery Cost">
                <b-form-input for="delivery_cost" name="delivery_cost" type="number"
                  v-model="supplierData.delivery_cost"></b-form-input>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group label="Delivery Date">
                <b-form-datepicker today-button reset-button close-button id="delivery_date"
                  v-model="supplierData.delivery_date" class="mb-2" :date-disabled-fn="dateDisabled"
                  :min="new Date()"></b-form-datepicker>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group label="Delivery Service">
                <b-form-input for="delivery_service" name="delivery_service" readonly
                  v-model="supplierData.delivery_service"></b-form-input>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <label>Deliver To</label>
              <b-form-select v-model="supplierData.delivery_type" :options="deliveryTypeOptions"></b-form-select>
            </b-col>
          </b-row>
        </b-form>

      </div>
    </b-sidebar>
    <div class="col-lg-4">
      <div class="card">
        <div class="card-header bg-transparent border-bottom">
          <h5 class="mb-0">Customer</h5>
          <br />
          <hr />
          <label for="" class="text-dark">SHIPPING ADDRESS</label>
          <address>
            {{ orderData.shipping_first_name }} {{ orderData.shipping_last_name }} <br>
            {{ orderData.shipping_company }} <br>
            {{ orderData.shipping_mobile }}
            <br />
            {{ orderData.shipping_street1 }} {{ orderData.shipping_city }},
            {{ orderData.shipping_country }}
            {{ orderData.shipping_postal_code }}
          </address>
          <hr class="my-4" />
          <label for="">BILLING ADDRESS</label>
          <address>
            {{ orderData.billing_first_name }} {{ orderData.billing_last_name }} <br>
            {{ orderData.billing_company }} <br>
            {{ orderData.billing_mobile }}
            <br />
            {{ orderData.billing_street1 }} {{ orderData.billing_city }},
            {{ orderData.billing_country }}
            {{ orderData.billing_postal_code }}
          </address>
        </div>
      </div>
      <b-card title="Admin Tracking details">
        <a href="javascript:void(0)" role="button" @click="dispatchModal = true">ADD ADMIN TRACKING</a>
        <div v-for="(item, index) in orderData.order_items" :key="index" class="border-top py-2 mt-2">
          <b>{{ item.product_data.name }}</b> <br>
          <div v-for="(track, t) in item.admin_tracking" :key="t">
            <b-button variant="success" size="sm" class="float-right" @click="updateTracking(track)">Edit</b-button>
            <b>Link :</b> <a :href="track.url">{{ track.url }}</a> <br>
            <b>ID : </b> {{ track.track_id }}
            <div class="bg-light"><b>Comment : </b> {{ track.comments }}</div>
            <b><i class="mdi mdi-clock-outline"></i> : </b> {{ $moment(track.created_at).tz('Europe/London').format("DD MMM, YYYY | HH:mm") }}
          </div>
        </div>
      </b-card>
    </div>
    <b-modal title="Add tracking" v-model="dispatchModal" hide-footer>
      <label>Please select the items you are dispatching</label>
      <hr>
      <b-form>
        <b-form-group v-slot="{ ariaDescribedby }">
          <b-form-radio-group id="radio-group-2" v-model="dispatchOption" :aria-describedby="ariaDescribedby"
            name="radio-sub-component">
            <b-form-radio value="all">All Products</b-form-radio>
            <b-form-radio value="select" :disabled="orderData.order_items.length < 2">Select Products</b-form-radio>
          </b-form-radio-group>
        </b-form-group>
        <b-form-group label="Select Products" v-slot="{ ariaDescribedby }" v-if="dispatchOption === 'select'">
          <b-form-checkbox-group stacked id="selectDispatch" v-model="selectedItems" :aria-describedby="ariaDescribedby"
            name="dispatch">
            <b-form-checkbox :disabled="product.admin_tracking.length > 0" :value="product.id"
              v-for="(product, p) in orderData.order_items" :key="p">
              {{  product.product_data.name || product.quotation_product.name}} <span v-if="product.admin_tracking.length > 0"
                class="text-danger">Dispatched</span></b-form-checkbox>
          </b-form-checkbox-group>
        </b-form-group>
        <hr>
        <b-form-group label="URL">
          <b-form-input type="url" required placeholder="Tracking url" v-model="dispatchData.url"></b-form-input>
        </b-form-group>
        <b-form-group label="Tracking ID">
          <b-form-input type="text" required placeholder="Tracking ID" v-model="dispatchData.track_id"></b-form-input>
        </b-form-group>
        <b-form-group label="Comment">
          <b-form-textarea type="text" required placeholder="Comment" v-model="dispatchData.comments"></b-form-textarea>
        </b-form-group>
        <b-button variant="primary" :disabled="dispatchData.url === '' || dispatchData.track_id === ''" size="sm"
          type="button" @click="addTracking" class="w-100">Add Details</b-button>
      </b-form>
    </b-modal>
    <b-modal v-model="deliveDateUpdateModal" title="Update delivery date" hide-footer>
      <b-form v-if="selectedDeliveryItem">
        <b-form-group label="Delivery Date">
          <b-form-datepicker today-button :date-disabled-fn="dateDisabled" reset-button close-button id="delivery_date"
            :min="selectedDeliveryItem.oldDeliveryDate" v-model="selectedDeliveryItem.delivery_date"
            @input="calculateDate"></b-form-datepicker>
        </b-form-group>
        <b-button variant="primary" size="sm" type="button" @click="updateDeliveryDate" class="w-100">Add
          Details</b-button>
      </b-form>
    </b-modal>
    <b-modal v-model="trackModal" hide-footer title="Update tracking details">
      <b-form v-if="selectedTracking">
        <b-row>
          <b-col cols="12">
            <b-form-group label="URL">
              <b-form-input type="text" v-model="selectedTracking.url"></b-form-input>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group label="URL">
              <b-form-input type="text" v-model="selectedTracking.track_id"></b-form-input>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group label="URL">
              <b-form-textarea v-model="selectedTracking.comments"></b-form-textarea>
            </b-form-group>
          </b-col>
          <b-col cols="12" class="text-right">
            <b-button class="w-100" type="button" variant="outline-success"
              @click="updateTrackingDetails">UPDATE</b-button>
          </b-col>
        </b-row>
      </b-form>
    </b-modal>
  </div>
</template>

<style>
.b-sidebar.b-sidebar-right>.b-sidebar-header .close {
  margin-left: auto;
  margin-right: 0 !important;
}

.link {
  padding: 4px 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.link:hover {
  background: #ddd;
}

.accepted {
  background-color: pink !important;
}

.in-production {
  background-color: rgb(64, 58, 40) !important;
  color: #FFF;
}

.dispatch {
  background-color: rgb(224, 160, 214) !important;
  color: #444;
}

.delivered {
  background-color: rgb(24, 129, 0) !important;
}

.delay {
  background-color: yellow !important;
}

#manageSupplier>>>ul {
  padding-left: 1rem !important;
}

.delivery-date-history ul {
  position: absolute;
  top: 0;
  right: 100%;
  background: white;
  border: #EFF2F7;
  padding: 8px 15px;
  list-style-type: none;
  width: 200px;
  border-radius: 8px;
  display: none;
}

.delivery-date-history:hover ul {
  display: block;
}

.delivery-date-history li:not(:last-child) {
  padding-bottom: 8px;
  border-bottom: 1px solid #EFF2F7;
}

.delivery-date-history li:not(:first-child) {
  padding-top: 8px;
}
</style>
