<script>
export default {
  data() {
    return {
      this_is_final: {},
      combinations: [],
      attributeData: [],
      attributeValues: [],
      uploadFile: true,
      isEdit: false,
      attribute_value: null,
      attribute_id: null,
      associatedvarients: null,
      id: null,
      selectedAttributeId: null,
      productAttributeData: [],
    };
  },

  mounted() {
    this.getvariant();
  },

  methods: {
    getvariant() {
      let loader = this.$loading.show({
        loader: "dots",
      });
      this.$store.getters.client
        .post(`variant/get-variant_only/`, { product_id: this.id })
        .then((response) => {
          this.this_is_final = response.data.data;
          this.this_is_final.variants = this.this_is_final.variants.map((x) => {
            return {
              ...x,
              prices: [
                {
                  quantity: 1,
                  price: 1,
                },
              ],
            };
          });
          loader.hide();
        })
        .catch((error) => {
          console.log(error);
          loader.hide();
        });
    },

    addField() {
      this.productAttributeData.push({
        attribute_value: [],
        id: null,
        all_attribute_val: [],
      });
    },

    addQuentityRow(item) {
      item.prices.push({ quantity: 1, price: 1 });
    },
    removeQuentityRow(item, val) {
      val.prices.splice(item, 1);
    },
    selectedAttribute(att, index) {
      this.selectedAttributeId = att.id;
      this.getAttributes(index);
    },

    selectedAttributeValue(att, index) {
      this.productAttributeData[index].attribute_value.push(att.id);
    },

    deleteField(index) {
      this.productAttributeData.splice(index, 1);
    },

    checkNumericInput(detail, field) {
      const value = parseFloat(detail[field]);
      if (isNaN(value)) {
        // Set the respective 'isInvalid' property to true if value is NaN
        detail[
          "isInvalid" + field.charAt(0).toUpperCase() + field.slice(1)
        ] = true;
      } else {
        // Set the respective 'isInvalid' property to false if value is valid
        detail[
          "isInvalid" + field.charAt(0).toUpperCase() + field.slice(1)
        ] = false;
      }
    },

    async submitDetails() {
      const dta = {
        product: this.data.id,
        description: JSON.stringify(
          this.payload.reduce((acc, item) => {
            acc[item.qty] = item.price;
            return acc;
          }, {})
        ),
      };
      await this.$validator.validateAll().then((result) => {
        if (result) {
          let loader = this.$loading.show({
            loader: "dots",
          });
          this.$store.getters.client
            .post("/variant/product-calculator/", dta)
            .then((response) => {
              let product_id = response.data.id;
              localStorage.setItem("product_id", product_id);
              this.$emit("updatePage", 1);
              // this.$router.push('/products');
              loader.hide();
            })
            .catch((err) => {
              loader.hide();
              alert(err);
            });
        }
      });
    },
    savePrice(items) {
      const dta = {
        variant: items.id,
        description: JSON.stringify(
          items.prices.reduce((acc, item) => {
            acc[item.quantity] = item.price;
            return acc;
          }, {})
        ),
      };
      let loader = this.$loading.show({
        loader: "dots",
      });
      this.$store.getters.client
        .post(`/variant/product-calculator/`, dta)
        .then(() => {
          loader.hide();
        })
        .catch((error) => {
          console.log(error);
          loader.hide();
        });
    },
  },

  created() {
    if ("id" in this.$route.params) {
      this.isEdit = true;
      this.id = this.$route.params.id;
    }
  },
};
</script>

<template>
  <div class="row">
    <div class="col-lg-12">
      <b-card title="Varients">
        <b-row class="mb-3">
          <div class="col-5">Combination</div>
          <div class="col">Area</div>
          <div class="col">Multiplier</div>
          <div class="col">Express</div>
          <div class="col">standard </div>
          <div class="col">Value</div>
          <div class="col"></div>
        </b-row>
        <b-row class="mb-3 py-3 border-bottom" v-for="(combination, index) in this_is_final.variants" :key="index">
          <b-col cols="5">
            <b-badge class="mr-2" variant="light" v-for="(val, j) in combination.option_values" :key="j">{{
              val.value.name }}</b-badge>
          </b-col>
          <b-col cols="7">
            <b-row class="mb-2" v-for="(detail, i) in combination.prices" :key="i">
              <div class="col">
                <b-form-input v-model="detail.quantity" size="sm" type="number" class="small-input"
                  @input="checkNumericInput(detail, 'quantity')"
                  :class="{ 'invalid-input': detail.isInvalidQuantity }"></b-form-input>
              </div>
              <div class="col">
                <b-form-input type="number" class="small-input" v-model="detail.price" size="sm"
                  @input="checkNumericInput(detail, 'price')"
                  :class="{ 'invalid-input': detail.isInvalidPrice }"></b-form-input>
              </div>
              <div class="col">
                <b-form-input type="number" class="small-input" size="sm"
                  @input="checkNumericInput(detail, 'express')"  :value="detail.quantity * detail.price * 1.25 || 0"
                  :class="{ 'invalid-input': detail.isInvalidExpress }"></b-form-input>
              </div>
              <div class="col">
                <b-form-input type="number" class="small-input" size="sm" :value="detail.quantity * detail.price || 0"
                  @input="checkNumericInput(detail, 'standard')"
                  :class="{ 'invalid-input': detail.isInvalidStandard }"></b-form-input>
              </div>
              <div class="col">
                <b-form-input type="number" class="small-input" size="sm"
                  @input="checkNumericInput(detail, 'value')"  :value="detail.quantity * detail.price * 0.9 || 0"
                  :class="{ 'invalid-input': detail.isInvalidValue }"></b-form-input>
              </div>
              <div class="col">
                <b-button variant="success" size="sm" v-if="i === combination.prices.length - 1"
                  @click="addQuentityRow(combination)">+</b-button>
                <b-button variant="primary" size="sm" v-if="i === combination.prices.length - 1"
                  @click="savePrice(combination)" class="mx-2">Save</b-button>
                <b-button variant="danger" size="sm" v-else @click="removeQuentityRow(i, combination)">-</b-button>
              </div>
            </b-row>
          </b-col>
        </b-row>
        <div class="d-flex justify-content-end">
          <button @click="$emit('previousPage', 1)" type="button" class="btn btn-danger float-right">
            Back
          </button>
        </div>
      </b-card>
    </div>
  </div>
</template>

<style>
.invalid-input {
  border-color: red !important;
}

.small-input {
  -moz-appearance: textfield;
}

.small-input::-webkit-inner-spin-button {
  display: none;
}

.small-input::-webkit-outer-spin-button,
.small-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>